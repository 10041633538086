/****************************************************
 Generic Variables
****************************************************/
/****************************************************
 Colors
****************************************************/
/****************************************************
 Media Queries
****************************************************/
/****************************************************
 Font sizing
****************************************************/
html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; }

body * {
  box-sizing: border-box; }

a {
  text-decoration: none; }
  a:link {
    color: #2f6f8a; }
  a:visited {
    color: #2f6f8a; }
  a:hover {
    color: #2f6f8a;
    text-decoration: underline; }
  a:active {
    color: #2f6f8a; }

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column; }
  .wrapper header {
    flex: 0 0 100px; }
    @media (min-width: 768px) {
      .wrapper header {
        flex: 0 0 50px; } }
  .wrapper .page-container {
    flex: 1 0 auto;
    background-attachment: fixed; }
    @media (min-width: 768px) {
      .wrapper .page-container {
        background: url("./LoggedInBackground.svg") no-repeat top left;
        background-attachment: fixed; } }
  .wrapper footer {
    flex: 0 0 60px;
    font-size: 1.3rem;
    z-index: 3;
    background-color: #d0d2c4; }
    @media (min-width: 768px) {
      .wrapper footer {
        flex: 0 0 30px; } }

.form-control {
  min-height: 40px;
  padding: 5px 15px;
  line-height: 30px; }

.input-lg {
  padding: 10px 15px;
  font-size: 1.6rem;
  line-height: 1.3333333;
  border-radius: 4px; }

p {
  margin: 0 0 1em 0; }

header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #000;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1000; }
  header .logo {
    flex: 1 0 100%;
    order: 3;
    max-height: 50px; }
  header .header-links {
    flex: 1 0 0;
    order: 1; }
  header .log-out {
    flex: 1 0 0;
    order: 2; }
  @media (min-width: 768px) {
    header {
      flex-wrap: nowrap; }
      header .logo {
        flex: 1 0 0;
        order: 2; }
      header .header-links {
        flex: 1 0 0;
        order: 1; }
      header .log-out {
        flex: 1 0 0;
        order: 3; } }

.logo {
  text-align: center; }
  .logo a {
    display: inline-block;
    padding: 8px 0;
    height: 100%; }
    .logo a img {
      height: 32px; }

.header-links {
  display: flex;
  font-size: 1.4rem; }
  .header-links .beta-wrapper {
    flex: 3 0 0;
    max-width: 244px;
    background-color: #54b0bd;
    line-height: 20px;
    padding: 15px;
    text-align: center; }
    .header-links .beta-wrapper .beta-label {
      display: none; }
      @media (min-width: 930px) {
        .header-links .beta-wrapper .beta-label {
          display: inline-block;
          position: relative;
          padding-right: 10px;
          color: #fff; }
          .header-links .beta-wrapper .beta-label:after {
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            right: 0;
            width: 1px;
            height: 12px;
            background-color: #fff; } }
    .header-links .beta-wrapper a {
      padding: 0; }
      @media (min-width: 768px) {
        .header-links .beta-wrapper a {
          padding-left: 10px; } }
      .header-links .beta-wrapper a:link {
        color: #fff; }
      .header-links .beta-wrapper a:visited {
        color: #fff; }
      .header-links .beta-wrapper a:hover {
        color: #fff; }
      .header-links .beta-wrapper a:active {
        color: #fff; }
  .header-links .faq-link {
    cursor: pointer;
    line-height: 20px;
    padding: 15px;
    color: #252525; }
    .header-links .faq-link:link {
      color: #252525; }
    .header-links .faq-link:visited {
      color: #252525; }
    .header-links .faq-link:hover {
      color: #252525; }
    .header-links .faq-link:active {
      color: #252525; }
  .header-links .email-alert-link {
    cursor: pointer;
    line-height: 20px;
    padding: 15px;
    color: #252525; }
    .header-links .email-alert-link:link {
      color: #252525; }
    .header-links .email-alert-link:visited {
      color: #252525; }
    .header-links .email-alert-link:hover {
      color: #252525; }
    .header-links .email-alert-link:active {
      color: #252525; }
  .header-links .contact-us-link {
    cursor: pointer;
    line-height: 20px;
    padding: 15px;
    color: #252525; }
    .header-links .contact-us-link:link {
      color: #252525; }
    .header-links .contact-us-link:visited {
      color: #252525; }
    .header-links .contact-us-link:hover {
      color: #252525; }
    .header-links .contact-us-link:active {
      color: #252525; }

.log-out {
  text-align: right; }
  .log-out .log-out-btn {
    margin: 10px 15px; }
    @media (min-width: 768px) {
      .log-out .log-out-btn {
        margin: 10px 30px; } }

footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 15px; }
  footer span {
    flex: 1 0 0;
    text-align: center; }
    footer span a {
      margin: 0 7.5px; }
    footer span.copyright {
      flex: 1 0 100%; }
  @media (min-width: 768px) {
    footer {
      position: sticky;
      bottom: 0;
      flex-wrap: nowrap; }
      footer span {
        flex: 1 0 0;
        text-align: left; }
        footer span a {
          margin: 0 15px 0 0; }
        footer span.copyright {
          flex: 1 0 0;
          text-align: right; } }

button {
  -webkit-appearance: none;
  appearance: none;
  border: none; }

button, a.button {
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 30px;
  padding: 0 15px; }
  button.log-in-btn, a.button.log-in-btn {
    background-color: #000;
    color: #fff;
    width: 100%;
    line-height: 40px;
    margin-top: 10px; }
  button.log-out-btn, a.button.log-out-btn {
    background-color: #54b0bd;
    color: #fff;
    width: 100px; }
  button.download-btn, button.copy-btn, a.button.download-btn, a.button.copy-btn {
    display: inline-block;
    background-color: #fff;
    color: #ec912e;
    border: 1px solid #ec912e;
    transition: all .2s linear; }
    button.download-btn:hover, button.copy-btn:hover, a.button.download-btn:hover, a.button.copy-btn:hover {
      background-color: #ec912e;
      color: #fff;
      text-decoration: none; }
  button.search-btn, a.button.search-btn {
    height: 40px;
    background-color: #2f6f8a;
    color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.login-form {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 60px;
  background-color: #fff;
  padding: 30px;
  overflow: auto;
  display: block; }
  @media (min-width: 768px) {
    .login-form {
      top: 50px;
      bottom: 30px;
      padding: 0;
      display: grid;
      grid-template-columns: 20% 20% 10% 3% 34% 13%;
      grid-template-rows: 1fr 100px 70px auto auto 2fr; } }

a.trial {
  background: #58b0bc;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
  border: 0;
  color: #fff; }

a.trial:hover {
  background: #2f6f8a;
  color: #fff; }

@media (max-width: 767px) {
  .login-form * {
    max-width: 480px;
    margin: 0 auto 15px auto; } }

.background-r-logged-out {
  display: none;
  grid-column: 1 / 4;
  grid-row: 1 / 7; }
  .background-r-logged-out img {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    float: right; }
  @media (min-width: 768px) {
    .background-r-logged-out {
      display: block; } }

h1 {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 30px 0;
  text-align: center; }

.intro {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  z-index: 2;
  text-align: center;
  color: #252525; }
  .intro p {
    font-size: 1.8rem; }
  .intro p:first-child {
    font-weight: 700; }
  @media (min-width: 768px) {
    .intro {
      text-align: left;
      color: #fff; } }

form {
  grid-column: 5 / 6;
  grid-row: 3 / 5; }
  form label {
    display: none; }
  form .form-group-lg .form-control {
    font-size: 1.6rem; }
  form .has-feedback label ~ .form-control-feedback {
    top: 0; }
  form .trouble {
    text-align: center;
    font-size: 1.4rem;
    color: #707070; }
    @media (min-width: 768px) {
      form .trouble {
        margin-top: 100px; } }

.welcome-screen {
  width: 100%;
  max-width: 768px;
  margin: 40px auto;
  padding: 15px; }
  @media (min-width: 768px) {
    .welcome-screen {
      margin: 80px auto; } }

.welcome-message {
  position: relative;
  padding-top: 40px;
  max-width: 560px;
  margin: 0 auto 60px auto; }
  @media (min-width: 768px) {
    .welcome-message {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr 5fr;
      padding-top: 0; } }
  .welcome-message .welcome-wrapper {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    z-index: 2;
    position: absolute;
    top: 0; }
    @media (min-width: 768px) {
      .welcome-message .welcome-wrapper {
        position: relative; } }
    .welcome-message .welcome-wrapper h2 {
      font-size: 6rem;
      font-weight: bold;
      margin: 0;
      padding: 0 15px 5px 0; }
  .welcome-message .welcome-box {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    position: relative;
    padding: 15px 15px 15px 15px;
    border: 2px solid #252525;
    background-color: #fff;
    top: 15px; }
    .welcome-message .welcome-box:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 270px;
      height: 30px;
      z-index: 1;
      background-color: #fff; }
      @media (min-width: 768px) {
        .welcome-message .welcome-box:before {
          width: 125px; } }
    .welcome-message .welcome-box p {
      margin: 15px 0 0 0; }

.article-list {
  padding: 15px;
  margin-top: 120px; }
  @media (min-width: 768px) {
    .article-list {
      padding: 0;
      display: grid;
      grid-template-columns: 100px minmax(300px, 30%) 50px minmax(400px, 60%) 100px;
      grid-template-rows: 40px auto 40px; } }

.territory-selection {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  position: fixed;
  margin: 20px;
  max-width: 33%; }
  @media (max-width: 768px) {
    .territory-selection {
      position: relative;
      max-width: 100%;
      margin-top: 80px; } }

.article-container {
  grid-column: 4 / 5;
  grid-row: 2 / 3; }

.article-list-title {
  font-size: 2.4rem;
  font-weight: 700;
  margin: 0 0 45px 0; }

.article-summary {
  display: block;
  margin-bottom: 40px; }
  .article-summary:link {
    color: #252525; }
    .article-summary:link .article-place {
      color: #2f6f8a; }
  .article-summary:visited {
    color: #252525; }
    .article-summary:visited .article-place {
      color: #2f6f8a; }
  .article-summary:hover {
    color: #252525;
    text-decoration: none; }
  .article-summary:active {
    color: #252525; }
  .article-summary .article-meta {
    font-size: 1.4rem;
    margin: 0 0 0.4em 0; }
    .article-summary .article-meta span {
      display: block; }
  .article-summary .article-slug {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 0 0.1em 0; }
  .article-summary .article-headline {
    font-size: 1.6rem;
    margin: 0; }

.panel-default > .panel-heading {
  padding: 0;
  background-color: transparent;
  font-weight: 700;
  margin: 0 0 15px 0; }

.panel-group {
  margin: 15px 0 0 0; }

.panel-group .panel {
  margin: 0;
  border: 0; }

.panel {
  background: transparent; }

.panel-body {
  padding: 0; }

.panel-body:after {
  display: none; }

.articles-place-description {
  font-weight: 700;
  margin: 0 0 15px 0; }

.input-group .form-control {
  font-size: 1.6rem; }

.input-lg {
  padding: 5px 15px; }

.input-group-addon {
  padding: 0;
  border: 0; }

.alert {
  margin-top: 20px;
  width: 100%;
  color: #fff;
  background-color: #54b0bd; }

article {
  width: 100%;
  max-width: 768px;
  padding: 15px;
  margin: 15px auto;
  margin-top: 120px; }
  article .back-link {
    display: block;
    margin-bottom: 25px;
    cursor: pointer; }
  article .article-meta span {
    display: block; }
    article .article-meta span.article-slug {
      color: #2f6f8a;
      font-weight: 700; }
  article h1 {
    text-align: left;
    margin: 40px 0 10px 0; }
  article .article-byline {
    color: #707070;
    font-style: italic; }
  article .article-source {
    padding-right: 1em; }
  article .button {
    margin: 20px 15px 30px 0; }
  article figure {
    width: 100%;
    text-align: center; }
    article figure img {
      max-width: 100%;
      margin: 0 auto; }

@media (max-width: 768px) {
  .modal-dialog .modal-content {
    padding: 10vw 1vw 1vw 1vw; } }

@media (min-width: 768px) {
  .modal-dialog .modal-content {
    padding: 4vw; } }

@media (min-width: 900px) {
  .modal-dialog .modal-content {
    padding: 4vw; } }

@media (min-width: 1200px) {
  .modal-dialog .modal-content {
    padding: 4vw; } }

.modal-dialog .modal-header {
  border: none;
  text-align: center; }
  .modal-dialog .modal-header button {
    margin-top: 10px !important; }
  .modal-dialog .modal-header .sr-only {
    color: #000;
    position: relative; }
  .modal-dialog .modal-header .close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 1em;
    color: #000; }
  .modal-dialog .modal-header h4 {
    font-size: 1.6em; }
  .modal-dialog .modal-header .logo {
    width: 15%;
    float: left; }

.modal-dialog .modal-footer {
  font-size: 0.8em; }

.react-cookie-banner {
  padding: 1em;
  z-index: 100;
  text-align: center;
  font-size: 0.9em; }
  .react-cookie-banner .cookie-message {
    margin-right: 1em;
    margin-bottom: 1em; }
  .react-cookie-banner .cookie-message a {
    margin-left: 0.2em;
    background-color: #ddd; }
  .react-cookie-banner .button-close {
    border: 2px #999 solid;
    background-color: #ddd;
    font-weight: bold; }
